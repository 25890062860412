<template>
    <div class="el-content">
        <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }">
			<a-form-item label="合同名称" required>
				<a-input v-model:value="form.name"></a-input>
			</a-form-item>
            <a-form-item label="甲方" required>
				<div class="ce-input" v-for="(item,index) in form.owner" :key="index">
                    <i class="ri-delete-bin-line ri-lg" @click="handldOwner(index)"></i>
                    <a-input class="ce-jia1" v-model:value="item.key" placeholder="输入标题"></a-input>
                    <a-input class="ce-jia2" v-model:value="item.value" placeholder="输入名称"></a-input>
                </div>
                <div class="ce-add" @click="handldOwner(-1)"><i class="ri-add-line ri-top"></i>添加</div>
			</a-form-item>
            <a-form-item label="乙方" required>
				<a-input v-model:value="form.party_b"></a-input>
			</a-form-item>
            <a-form-item label="公章" required>
                <kd-img-select :src="form.common_seal" @change="(url)=>{ form.common_seal = url}"></kd-img-select>
            </a-form-item>
			<a-form-item label="排序" >
				<a-input v-model:value="form.sort"></a-input>
			</a-form-item>
			<a-form-item label="合同内容" required>
                <tinymce-editor v-model="form.agreement"></tinymce-editor>
			</a-form-item>
			<a-form-item :wrapper-col="{ offset: 3 }">
				<a-space>
					<a-button @click="submitSave" type="primary" >保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import adoptModel from '@/api/adopt'
import { useRoute } from 'vue-router'
export default {
    setup() {
        const state = reactive({
            form:{
                id:0,
                name:"",
                party_b:"",
                agreement:"",
                common_seal:"",
                sort:99,
                owner:[{key:"",value:""}] 
            }
        })

        const opt = useRoute().query
        if( opt.id ){
            adoptModel.getContractDetail(opt.id,Object.keys(state.form) ,res=>state.form = res)
        }
        const handldOwner = index=>{
            if( index == -1 ){
                state.form.owner.push({key:"",value:""})
                return
            }
            state.form.owner.splice(index,1)
        }

        const submitSave = ()=>adoptModel.addOrEditContract(JSON.parse(JSON.stringify(state.form)))

        return{
            ...toRefs(state),
            handldOwner,
            submitSave
        }
    },
}
</script>
<style lang="scss" scoped>
.ce-input{
    width: 100%;
    display: flex;
    margin-bottom: 12px;
    .ri-delete-bin-line{
        position: relative;
        top: 8px;
        margin-right: 6px;
        cursor: pointer;
    }
    .ce-jia1{
        width: 200px;
    }
    .ce-jia2{
        width: calc(100%-220px);
        margin-left: 12px;
    }
}
.ce-add{
    width: 100%;
    height: 32px;
    border: 1px dashed #999;
    text-align: center;
    line-height: 32px;
    margin-top: 12px;
    cursor: pointer;
}
</style>